import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'

import '../App.css'
import logo from '../images/logo.png'

import { useAuth } from '../hooks/use-auth.js'

import LoadingBar from '../components/LoadingBar'

const Login = ({ loading, setLoading }) => {
  // const { dispatch } = useContext(Context)

  const auth = useAuth()

  let history = useHistory()

  const handleLogin = async () => {
    try {
      // console.log('set loading true')
      auth?.setLoading(true)
      await auth.signin()
      auth?.setLoading(false)
      // console.log('***********set loading false.')

      // if (signInSuccessful.credential.accessToken) {
      //   setLoading(false)
      //   if (
      //     history.location &&
      //     history.location.state &&
      //     history.location.state.from
      //   ) {
      //     console.log('history pushing from Login component')
      //     history.push(history.location.state.from.pathname)
      //   } else {
      //     console.log('history.push to / from else in Login comp')
      //     history.push('/')
      //   }
      // } else {
      //   console.log('login not successful.')
      // }
    } catch (error) {
      console.log('error with login', error)
    }
  }

  //TODO check if this is needed
  React.useEffect(() => {
    if (auth.user) {
      if (
        history.location.state &&
        history.location.state.from.pathname !== '/login'
      ) {
        // console.log('####### Pushing from history')
        // console.log('this:  ', history.location.state.from.pathname)
        // setLoginRedirect(false)
        history.push(history.location.state.from.pathname)
      } else {
        // console.log('Pushing to /')
        history.push('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user])

  return (
    <div className='center-page column grow'>
      <img src={logo} className='logo' alt='logo' />
      {auth?.loading ? (
        <LoadingBar></LoadingBar>
      ) : (
        <div className='row'>
          <Button
            className='button spaced button-normal'
            variant='contained'
            color='primary'
            onClick={() => handleLogin()}
          >
            Login
          </Button>
        </div>
      )}
    </div>
  )
}

export default Login
