import React from 'react'
// import { useHistory } from 'react-router-dom'

// import { Button } from '@material-ui/core'

import '../App.css'
// import logo from '../images/logo.png'

// import { useAuth } from '../hooks/use-auth.js'

// import LoadingBar from '../components/LoadingBar'

import Login from '../components/Login'

const LoginPage = () => {
  // const [loading, setLoading] = useState(false)
  // const { dispatch } = useContext(Context)

  // const auth = useAuth()

  // return <Login loading={loading} setLoading={setLoading}></Login>
  return <Login></Login>
}

export default LoginPage
